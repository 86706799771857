<template>
  <div class="viewEarnings">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="viewEarnings-back"
      @click="back()"
    />
    <div class="viewEarnings-content">
      <div class="viewEarnings-content-user flex">
        <img
          :src="suerInfo.headImg"
          alt=""
          class="viewEarnings-content-user-img"
        />
        <div class="">
          <div class="flex" style="align-items: center">
            <div class="viewEarnings-content-user-title">今日获得：</div>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/crystalintegration.png"
              alt=""
              class="viewEarnings-content-user-numImg"
            />
            <div class="viewEarnings-content-user-num">
              {{ acquireData.todaySum ? acquireData.todaySum : 0 }}
            </div>
          </div>
          <div class="flex" style="align-items: center; margin-top: 12px">
            <div class="viewEarnings-content-user-title">累计获得：</div>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/crystalintegration.png"
              alt=""
              class="viewEarnings-content-user-numImg"
            />
            <div class="viewEarnings-content-user-num">
              {{ acquireData.totalSum ? acquireData.totalSum : 0 }}
            </div>
          </div>
        </div>
      </div>
      <div class="viewEarnings-content-type flex">
        <div>好友UID</div>
        <div>加成</div>
      </div>
      <div class="viewEarnings-content-ul" @scroll="handleScrolls">
        <div
          v-for="(item, index) in subordinateincome"
          :key="index"
          class="viewEarnings-content-ul-li flex"
        >
          <div
            class="flex viewEarnings-content-ul-li-left"
            style="align-items: center"
          >
            <div>{{ item.invitationUserId }}</div>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/mine/Group102531123.png"
              mode=""
              class="viewEarnings-content-ul-li-left-img"
              @click="viewSubordinates(item)"
            />
          </div>
          <div
            class="flex viewEarnings-content-ul-li-right"
            style="align-items: center"
          >
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/Group10681.png"
              mode=""
              class="viewEarnings-content-ul-li-right-img"
            />

            <div class="viewEarnings-content-ul-li-right-num">
              {{ item.crystalIntegral ? item.crystalIntegral : 0 }}
            </div>
          </div>
        </div>
      </div>
      <el-dialog title="" :visible.sync="show" width="30%" :show-close="false">
        <div class="subordinate">
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/mine/notyetClose.png"
            alt=""
            class="subordinate-back"
            @click="show = !show"
          />
          <div class="viewEarnings-content-type flex widyh">
            <div>盟友UID</div>
            <div>加成</div>
          </div>
          <div class="viewEarnings-content-ul" @scroll="handleScroll">
            <div
              v-for="(item, index) in subordinateList"
              :key="index"
              class="viewEarnings-content-ul-li flex widyh354"
            >
              <div
                class="flex viewEarnings-content-ul-li-left"
                style="align-items: center"
              >
                <div>{{ item.invitationUserId }}</div>
                <!-- <img
                  src="https://cdn.bubbleplan.cn/m_bubble/mine/Group102531123.png"
                  mode=""
                  class="viewEarnings-content-ul-li-left-img"
                  @click="viewSubordinates(item)"
                /> -->
              </div>
              <div
                class="flex viewEarnings-content-ul-li-right"
                style="align-items: center"
              >
                <img
                  src="https://cdn.bubbleplan.cn/m_bubble/activity/Group10681.png"
                  mode=""
                  class="viewEarnings-content-ul-li-right-img"
                />

                <div class="viewEarnings-content-ul-li-right-num">
                  {{ item.crystalIntegral ? item.crystalIntegral : 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      acquireData: {},
      hasNextPage: false,
      page: {
        pageNum: 1,
        pageSize: 15,
      },
      subordinateincome: [],
      invitationUserId: "",
      subordinateList: [],
      show: false,
      hasNextPages: false,
      pages: {
        pageNum: 1,
        pageSize: 15,
      },
    };
  },
  mounted() {
    this.crystalIntegralTodayAndTotal();
  },
  methods: {
    handleScroll() {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight + 6 >= scrollHeight) {
        if (this.hasNextPages) {
          this.pages.pageNum++;
          this.subordinateScrolltolower("bottom");
        }
      }
    },

    async subordinateScrolltolower(type) {
      const params = {
        ...this.pages,
        queryUserId: this.invitationUserId,
      };
      const { data, code } = await this.$ajax.mySubordinates(params);
      if (type == "bottom") {
        this.subordinateList.push(...data.list);
      } else {
        this.subordinateList = data.list;
      }
      this.hasNextPages = data.hasNextPage;
    },
    viewSubordinates(item) {
      this.invitationUserId = item.invitationUserId;
      this.subordinateList = [];
      this.show = true;
      this.subordinateScrolltolower();
    },
    handleScrolls() {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight + 6 >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.mySubordinates("bottom");
        }
      }
    },

    //查看下级收益

    async mySubordinates(type) {
      const params = {
        ...this.page,
      };
      const { data, code } = await this.$ajax.mySubordinates(params);
      if (type == "bottom") {
        this.subordinateincome.push(...data.list);
      } else {
        this.subordinateincome = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },
    //查看累计
    async crystalIntegralTodayAndTotal() {
      const { data, code } = await this.$ajax.crystalIntegralTodayAndTotal();
      this.acquireData = data;
      this.mySubordinates();
    },
    back() {
      history.back();
    },
  },
};
</script>


<style lang="scss" scoped>
.viewEarnings {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/activity/bgc.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 40px;
  padding-bottom: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  &-content {
    flex: 1;
    width: 1098px;
    background: url("https://cdn.bubbleplan.cn/static/activity/Group10850.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 31px 57px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &-user {
      width: 608px;
      height: 184px;
      background: url("https://cdn.bubbleplan.cn/static/activity/Group10849.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 30px 78px;

      align-items: center;
      &-img {
        width: 97px;
        height: 97px;
        border-radius: 50%;
        margin-right: 128px;
        margin-top: -20px;
      }
      &-title {
        color: #ffffff;
        font-size: 20rpx;
        font-weight: Bold;
        margin-right: 20.5px;
      }
      &-numImg {
        width: 28px;
        height: 34px;
        margin-right: 10px;
      }
      &-num {
        text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
          2px 2px 0 #000000;
        /* 模拟描边效果 */
        color: #ffffff;
        font-size: 23px;
        font-weight: Bold;
      }
    }
    &-type {
      margin: auto;
      margin-top: 10px;
      width: 466px;
      height: 64px;
      background: url("https://cdn.bubbleplan.cn/static/activity/Vector756.png")
        no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 80px;
      color: #fbeeff;
      font-size: 20px;
      font-weight: Bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
    }
    &-ul::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-ul {
      flex: 1%;
      margin-top: 15px;
      overflow: auto;
      &-li {
        width: 505px;
        height: 55px;
        background: url("https://cdn.bubbleplan.cn/static/activity/Maininterface_back_img_005.png")
          no-repeat;
        background-size: 100% 100%;
        margin: auto;
        margin-bottom: 10px;
        padding: 0 78px;
        align-items: center;
        justify-content: space-between;
        &-left {
          color: #ffffff;
          font-weight: bold;
          font-size: 20px;
          &-img:hover {
            cursor: pointer;
          }
          &-img {
            width: 26px;
            height: 26px;
            margin-left: 9.08px;
          }
        }
        &-right {
          &-img {
            width: 40px;
            height: 42px;
            z-index: 9;
          }
          &-num {
            padding: 0 18px;
            height: 35px;
            line-height: 35px;
            border-radius: 0px 131px 131px 0;
            background: #5f4e81;
            color: #ffffff;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.6px;
            margin-left: -6px;
          }
        }
      }
    }

    :deep(.el-dialog__header) {
      padding: 0;
    }
    :deep(.el-dialog__body) {
      padding: 0;
    }
    :deep(.el-dialog) {
      background: transparent;
      box-shadow: none;
    }
    .subordinate {
      width: 410px;
      height: 615px;
      background: url("https://cdn.bubbleplan.cn/static/activity/Group10838.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px 28px;
      padding-bottom: 40px;
      &-back:hover {
        cursor: pointer;
      }

      &-back {
        position: absolute;
        width: 47px;
        height: 46px;
        right: -18px;
        top: -12px;
      }
      .widyh {
        width: 327px !important ;
        padding: 0 43px !important;
      }
      .widyh354 {
        padding: 0 37px !important;
        width: 354px !important;
      }
    }
  }
}
</style>